exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-company-careers-js": () => import("./../../../src/pages/company/careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-marketplace-index-js": () => import("./../../../src/pages/marketplace/index.js" /* webpackChunkName: "component---src-pages-marketplace-index-js" */),
  "component---src-pages-marketplace-search-js": () => import("./../../../src/pages/marketplace/search.js" /* webpackChunkName: "component---src-pages-marketplace-search-js" */),
  "component---src-pages-release-notes-js": () => import("./../../../src/pages/release-notes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-templates-blog-author-page-js": () => import("./../../../src/templates/blog/authorPage.js" /* webpackChunkName: "component---src-templates-blog-author-page-js" */),
  "component---src-templates-blog-authors-page-js": () => import("./../../../src/templates/blog/authorsPage.js" /* webpackChunkName: "component---src-templates-blog-authors-page-js" */),
  "component---src-templates-blog-category-page-js": () => import("./../../../src/templates/blog/categoryPage.js" /* webpackChunkName: "component---src-templates-blog-category-page-js" */),
  "component---src-templates-blog-overview-page-js": () => import("./../../../src/templates/blog/overviewPage.js" /* webpackChunkName: "component---src-templates-blog-overview-page-js" */),
  "component---src-templates-blog-post-page-js": () => import("./../../../src/templates/blog/postPage.js" /* webpackChunkName: "component---src-templates-blog-post-page-js" */),
  "component---src-templates-blog-tag-page-js": () => import("./../../../src/templates/blog/tagPage.js" /* webpackChunkName: "component---src-templates-blog-tag-page-js" */),
  "component---src-templates-blog-tags-page-js": () => import("./../../../src/templates/blog/tagsPage.js" /* webpackChunkName: "component---src-templates-blog-tags-page-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/caseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-marketplace-details-page-js": () => import("./../../../src/templates/marketplace/detailsPage.js" /* webpackChunkName: "component---src-templates-marketplace-details-page-js" */),
  "component---src-templates-marketplace-type-list-page-js": () => import("./../../../src/templates/marketplace/typeListPage.js" /* webpackChunkName: "component---src-templates-marketplace-type-list-page-js" */),
  "component---src-templates-marketplace-types-page-js": () => import("./../../../src/templates/marketplace/typesPage.js" /* webpackChunkName: "component---src-templates-marketplace-types-page-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/staticPage.js" /* webpackChunkName: "component---src-templates-static-page-js" */)
}

